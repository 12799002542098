import React from 'react';
import {Button, BUTTON_TYPES, BUTTON_VARIANT} from '@klenty/klenty-ui';

import "./Card.css"; 

interface CardProps {
    title: string;
    key: string;
    path: string;
}

export default function Card({ title, key, path }: CardProps) {
    const handleClick = () => {
        console.log(path);
        window.location.href = path + "/companies";
    }
    return (
        <div className='Card' key={key}>
            <div className='Card_Title'>
                {title}
            </div>
            <div style={{margin:'10px 0px 0px 0px'}}>
                <Button variant={BUTTON_VARIANT.NORMAL} type={BUTTON_TYPES.PRIMARY} onClick={handleClick}>
                    View Dashboard
                </Button>
            </div>
        </div>
    )
}
