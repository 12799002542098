import "./box.css";
import { serverNameMapping } from "../../utils/constants";

function Box({ name, active }: { name: any; active: boolean }) {
  return (
    <div className="box">
      <div className="box-title">{serverNameMapping[name]}</div>
      <div className="running">
        {active ? (
          <div className="active">Active</div>
        ) : (
          <div className="in-active"> Inactive</div>
        )}
      </div>
    </div>
  );
}
export default Box;
