import { useEffect, useState } from "react";
import {
  getAllClusterStats,
  getClusterTemplate,
} from "../../../services/ClusterStatusServices";

import StatsCard from "../../../components/statsCard/StatsCard";
import "./healthCheck.css";
import KlentyLogo from "../../../assets/img/klenty-icon.svg";
import { useHistory } from "react-router";

function HealthDashboard() {
  const [clusterStats, setClusterStats] = useState<any>();
  const [template, setTemplate] = useState<Array<any>>();
  const history = useHistory();

  useEffect(() => {
    getClusterTemplate()
      .then((response) => setTemplate(response.data))
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    const timer = setInterval(() => {
      getAllClusterStats()
        .then((response: any) => setClusterStats(response.data))
        .catch((err) => console.error(err));
    }, 10000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="background">
      <img src={KlentyLogo} alt="klenty-logo" className="klenty-logo" onClick={() => history.goBack()} />
      <div className="heath-dashboard">
        <div className="dashboard-header">
          <h2>Services Health Dashboard</h2>
        </div>
        <div className="cluster-wrapper">
          {(clusterStats && Object.keys(clusterStats).length) ? (
            template?.map((cluster: any) => (
              <StatsCard
                clusterName={cluster.clusterName}
                services={cluster.services}
                stats={clusterStats[cluster.clusterName]}
              />
            ))
          ) : (
            <div className="loading">Fetching...</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HealthDashboard;
