import Box from "../box";
import "./statsCard.css";

function StatsCard({
  clusterName,
  services,
  stats,
}: {
  clusterName: string;
  services: any;
  stats: any;
}) {
  return (
    <div className="statsCard">
      <span className="cluster-title">{clusterName}</span>
      <>
        <div className="stats-layout">
          {services?.map((service: any) => (
            <Box
              active={stats[service === "webApp" ? "webAppGreen" : service]}
              name={service}
            />
          ))}
        </div>
      </>
    </div>
  );
}
export default StatsCard;
