import React, { useState } from "react";
import kl_logo from "../../../assets/img/kl_new.png";
import {
  Button,
  BUTTON_TYPES,
  BUTTON_VARIANT,
  TextInput,
  TEXT_INPUT_TYPES,
} from "@klenty/klenty-ui";
import axios from "axios";
import "./login.css";

type setLoginFunction = (a: boolean) => void;
interface setLoginFunction1 {
  setLoginState: setLoginFunction;
  setUser:(data:any) => any
}

function Login({ setLoginState ,setUser}: setLoginFunction1) {

  const handleLogin = async () => {
    const url = process.env.REACT_APP_APPLICATION_BASE_URL+ "/auth/google?isDashboardRedirect=true"
    window.open(url, '_self', 'width=500,height=500,left=10,top=150')
  };

  return (
    <header className="App-header">
      <img src={kl_logo} alt="Kl_logo" height={100} width={200} />
      <Button
        variant={BUTTON_VARIANT.NORMAL}
        type={BUTTON_TYPES.PRIMARY}
        onClick={handleLogin}
      >
        Sign in with Google
      </Button>
    </header>
  );
}

export default Login;
