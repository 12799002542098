import { MenuItem } from "@klenty/klenty-ui";
import { useEffect, useRef, useState } from "react";
import "./singleselect.css";

const SearchableDropdown = ({
  options,
  id,
  handleChange,
  disabled,
  onSelect,
  query,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  function toggle(e: any) {
    setIsOpen(e && e.target === inputRef.current);
  }

  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            value={query}
            disabled={disabled}
            ref={inputRef}
            type="text"
            placeholder="Search By Username"
            onChange={(e) => handleChange(e.target.value)}
            onClick={toggle}
          />
        </div>
      </div>
      <div className={`options ${isOpen ? "open" : ""}`}>
        {options.map((option: any, index: any) => {
          return (
            <div
              onClick={() => onSelect(option)}
              className={"option"}
              key={`${id}-${index}`}
            >
              <MenuItem
                text={option.username}
                subText={`${option.clusterId} - ${option.workspaceName}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
